import { ActionContext, Commit, Dispatch } from 'vuex';
import parseCanVote from './parseCanVote';
import cleanMessage from './cleanMessage';
import { MessageState, StateRoot, VoteTotalsState } from '@/types/store';
import { GusInit } from '@/types/buttons';
import { AgendaItemCurrent, Speaker, SpeakerChange, RequestListItem } from '@/types/meeting';
import { LoginDone, NameOffice } from '@/types/panel';


// The big switch/case that turns 1 RPC call into lots of method calls
export default function messageParse(msg: string | undefined, context: ActionContext<MessageState, StateRoot>): void {

  const { commit, dispatch }: {commit: Commit; dispatch: Dispatch} = context;

  const m = cleanMessage(msg);
  if (!m) {
    return; // ignore blank messages
  }

  const currentAuthToken = () => context.rootState.panel.authToken;


  /*eslint no-case-declarations: "off"*/
  // because parsing out the pieces inline is easier than a func call for each

  const parts = m.split('|');
  const cmd = parts[0];
  switch (cmd) {
    //
    // commands with a fixed first first pipe-delimited arg
    //
    case '@GUITICKLE_':
      // no-op
      return;

      //
      // login / logout:
      //
    case 'LOGIN':
      // even if it doesn't have enough parts
      dispatch('panel/loginDone', {
        authenticated: parts[1] === 'True',
        username: parts[2],
        authToken: parts[3],
        panelId: parseInt(parts[4], 10),
        canVote: parseCanVote(parseInt(parts[5], 10)),
        panelOffice: parts[6],
        panelName: parts[7]
      } as LoginDone, {root: true});
      return;

    case 'LOGOFF':
      dispatch('panel/logout', undefined, {root: true});
      return;

      //
      // init:
      //
    case 'GUS_INIT':
      if (parts.length < 6) {
        dispatch('message/unhandledMessage', msg, {root: true});
        return;
      }
      if (parts[1] !== currentAuthToken()) {
        dispatch('message/otherUserMessage', msg, {root: true});
        return;
      }
      const yesno = (parts[2] || '').split('_');
      commit('buttons/gusInit', {
        //authToken: parts[1],
        yesText: yesno[0] || 'YES',
        noText: yesno[1] || 'NO',
        lockMotion: parts[3] === 'True',
        hideVote: parts[4] === 'True',
        changeVote: parts[5] === 'True'
        //chair: parts[6] === 'True',
        //allSpeakers: parts[7] === 'True'
      } as GusInit, {root: true});
      return;
    case 'GUS_NMOFC':
      if (parts[1] !== currentAuthToken()) {
        dispatch('message/otherUserMessage', msg, {root: true});
        return;
      }
      commit('panel/nameOffice', {
        //authToken: parts[1],
        panelId: parseInt(parts[2], 10),
        canVote: parseCanVote(parseInt(parts[3], 10)),
        panelOffice: parts[4],
        panelName: parts[5]
      } as NameOffice, {root: true});
      return;
    case 'MBR_NMOFC':
      commit('panel/nameOffice', {
        panelId: parseInt(parts[1], 10),
        canVote: true, // ASSUME:
        panelOffice: parts[2],
        panelName: parts[3]
      } as NameOffice, {root: true});
      return;
    case 'AGN_URL':
      commit('meeting/iframeUrl', parts[1], {root: true});
      return;
    case 'VID_URL':
      commit('meeting/videoUrl', parts[1], {root: true});
      return;
    case 'GUS_STATE':
      commit('meeting/voteState', parts[2], {root: true});
      return;

      //
      // agenda:
      //
    case 'AGN_ITEM':
      commit('meeting/agendaItem', {
        agendaCurrentNumber: parts[1],
        agendaCurrentText: parts[2]
      } as AgendaItemCurrent, {root: true});
      return;
    case 'SPK_NAME':
      // add speaker to the existing list
      dispatch('meeting/addSpeakerToList', {
        id: parts[1],
        speakerState: parseInt(parts[2], 10),
        name: parts[3],
        topic: parts[4]
      } as Speaker, {root: true});
      return;
    case 'SPK_STATE':
      // speaker is in queue, recognize or withdrawn
      dispatch('meeting/changeSpeakerState', {
        id: parts[1],
        speakerState: parseInt(parts[2], 10)
      } as SpeakerChange, {root: true});
      return;
    case 'SPK_LISTAGN':
      // add speaker to the existing list
      const clear = parts[1] === 'True';
      if (clear) {
        commit('meeting/clearSpeakerList', undefined, {root: true});
      } else {
        dispatch('meeting/addSpeakerToList', {
          id: parts[3],
          speakerState: parseInt(parts[4], 10),
          name: parts[5],
          topic: parts[6]
        } as Speaker, {root: true});
      }
      return;
    case 'RTS_NAME':
      switch (parts[1]) {
        case 'Add': // add to list
          commit('meeting/requestListAdd', {
            name: parts[2],
            id: parts[3]
          } as RequestListItem, {root: true});
          return;
        case 'Rem': // remove from list
          commit('meeting/requestListRemove', parts[2], {root: true});
          return;
        case 'Sel': // recognize speaker
          commit('meeting/requestListRecognize', parts[2], {root: true});
          commit('meeting/requestListRemove', parts[2], {root: true});
          return;
        case 'CReg': // unrecognize speaker
          commit('meeting/requestListUnrecognize', undefined, {root: true});
          return;
        case 'CLst': // clear list
          commit('meeting/requestListClear', undefined, {root: true});
          commit('meeting/requestListUnrecognize', undefined, {root: true});
          return;
      }
      break;

      //
      // voting:
      //
    case 'CLEAR_VOTE':
      dispatch('meeting/clearVote', undefined, {root: true});
      return;
    case 'RESET_VOTE':
      dispatch('meeting/resetVote', undefined, {root: true});
      return;
    case 'RESET_PNLS':
      dispatch('meeting/resetVotePanel', undefined, {root: true});
      return;
    case 'MBR_MOT':
      // make or second motion
      if (parts.length === 3) {
        switch (parts[2]) {
          case '4':
            commit('meeting/motionFirstBy', parts[1], {root: true});
            return;
          case '-4':
            commit('meeting/motionFirstBy', '', {root: true});
            return;
          case '8':
            commit('meeting/motionSecondBy', parts[1], {root: true});
            return;
          case '-8':
            commit('meeting/motionSecondBy', '', {root: true});
            return;
        }
      }
      break;
    case 'START_VOTE':
      dispatch('meeting/startVote', undefined, {root: true});
      return;
    case 'RECORD_VOTE':
      dispatch('meeting/recordVote', undefined, {root: true});
      return;
    case 'CANCEL_VOTE':
      dispatch('meeting/cancelVote', undefined, {root: true});
      return;
    case 'VOTE_CNT':
      commit('voteTotals/setVote', {
        yes: parseInt(parts[1], 10),
        no: parseInt(parts[2], 10),
        abstain: parseInt(parts[3], 10),
        recused: parseInt(parts[4], 10),
        absent: parseInt(parts[5], 10)
      } as VoteTotalsState, {root: true});
      return;

      //
      // mic button
      //
    case 'RTS_DISCUS_ON':
      commit('buttons/micModeOn', undefined, {root: true});
      return;
    case 'RTS_DISCUS_OFF':
      commit('buttons/micModeOff', undefined, {root: true});
      return;
    case 'RTS_LIST_ONLY':
      commit('buttons/micModeListOnly', undefined, {root: true});
      return;


    default:
      //
      // commands with a parameter in the first pipe-delimited arg
      //
      const cmdParts = cmd.split('_');
      switch (cmdParts[0]) {

        //
        // voting
        //
        case 'MOTION':
          switch (cmdParts[1]) {
            case '4':
              if (parts[1] === '1') {
                commit('buttons/motionFirstByOtherUser', undefined, {root: true});
              } else {
                // other user un-made the motion
                dispatch('meeting/resetVote', undefined, {root: true});
              }
              return;
            case '8':
              if (parts[1] === '1') {
                // other user seconded the motion
                commit('buttons/motionSecondByOtherUser', undefined, {root: true});
              } else {
                // other user un-seconded the motion
                commit('buttons/motionFirstByOtherUser', undefined, {root: true});
              }
              return;
            }
          break;
        case 'GUPNL':
          const byMe = parts[1] === currentAuthToken();
          const action = parseInt(cmdParts[1], 10);

          /*
          None = 0
          Yes = 1
          No = 2
          YesNoAbstain = 3
          MakeMotion = 4
          MakeMotionYes = 5
          MakeMotionNo = 6
          MakeMotionYesNoAbstain = 7
          SecondMotion = 8
          SecondMotionYes = 9
          SecondMotionNo = 10
          SecondMotionYesNoAbstain = 11
          Abstain = 12
          Absent = 13
          Recusal = 14
          Motion1Motion2 = 15
          Abstain+MakeMotion = 16
          Abstain+SecondMotion = 20
          */
          switch (action) {
            case 0:
              dispatch('meeting/clearVote', undefined, {root: true});
              return;
            case 1: // Yes button
              if (byMe) {
                commit('buttons/yesButtonByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
            case 2: // No button
              if (byMe) {
                commit('buttons/noButtonByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
            /*
            case 3: // Yes + No + Abstain button
              break; // unhandled
            */
            case 4: // Make Motion on
              if (byMe) {
                dispatch('buttons/firstMotionByMe', undefined, {root: true});
              } else {
                dispatch('buttons/firstMotionByOtherUser', undefined, {root: true});
              }
              return;
            case -4: // Make Motion off
              dispatch('buttons/firstMotionOff', undefined, {root: true});
              return;
            case 5: // Yes + Make Motion buttons after vote is recorded
              if (byMe) {
                commit('buttons/yesButtonByMe', undefined, {root: true});
                commit('buttons/firstMotionByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
            case 6: // No + Make Motion buttons after vote is recorded
              if (byMe) {
                commit('buttons/noButtonByMe', undefined, {root: true});
                commit('buttons/firstMotionByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
            /*
            case 7: // Yes, No, Abstain + Make Motion buttons
              break; // unhandled message
            */
            case 8: // Second Motion on
              if (byMe) {
                dispatch('buttons/secondMotionByMe', undefined, {root: true});
              } else {
                dispatch('buttons/secondMotionByOtherUser', undefined, {root: true});
              }
              return;
            case -8: // Second Motion off
              dispatch('buttons/secondMotionOff', undefined, {root: true});
              return;
            case 9: // Yes + Second Motion Buttons after vote is recorded
              if (byMe) {
                commit('buttons/yesButtonByMe', undefined, {root: true});
                commit('buttons/secondMotionByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
            case 10: // No + Second Motion Buttons after vote is recorded
              if (byMe) {
                commit('buttons/noButtonByMe', undefined, {root: true});
                commit('buttons/secondMotionByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
            /*
            case 11: // Yes, No, Abstain + Make Motion buttons
              break;
            */
            case 12: // Abstain Button
              if (byMe) {
                commit('buttons/abstainButtonByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
            case 16: // Abstain + Motion
              if (byMe) {
                commit('buttons/abstainButtonByMe', undefined, {root: true});
                commit('buttons/firstMotionByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
            case 20: // Abstain + SecondMotion
              if (byMe) {
                commit('buttons/abstainButtonByMe', undefined, {root: true});
                commit('buttons/secondMotionByMe', undefined, {root: true});
              } else {
                // do nothing
              }
              return;
          }
          break;

          //
          // mic button
          //
        case 'MIC':
        case 'RTS':
          if (parts[1] !== currentAuthToken()) {
            dispatch('message/otherUserMessage', msg, {root: true});
            return;
          }
          switch (cmdParts[1]) {
            case '0':
              commit('buttons/micOff', undefined, {root: true});
              return;
            case '1':
              commit('buttons/micOn', undefined, {root: true});
              return;
            case '2':
              commit('buttons/micBlink', undefined, {root: true});
              return;
          }
          break;

          //
          // header
          //
        case 'TM':
          commit('panel/timer', cmdParts[1], {root: true});
          return;
        case 'TL':
          commit('panel/timerLight', cmdParts[1], {root: true});
          return;
        /*
        case 'SPK':
          dispatch('message/unhandledMessage', msg, {root: true});
          return;
        */

      }
  }

  // if we're still here, it wasn't a handled message
  dispatch('message/unhandledMessage', msg, {root: true});
}
