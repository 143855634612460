











import Vue from 'vue';
import { mapState } from 'vuex';


export default Vue.extend({

  computed: {

    videoFullUrl() {
      return this.videoUrl ?? '';
    },

    ...mapState('meeting', [
      'videoUrl'
    ])

  }

});
